import { RouteComponentProps } from '@reach/router'
import axios from 'axios'
import moment from 'moment'
import React, { Component } from 'react'
import Modal from 'react-modal'
import { RequestGetter } from '../../helpers/request'
import { HelmetComponent } from '../HelmetComponent'
import Loading from '../Loading'
import { RestaurantContainer as EventTitleContainer, StyledRow as EventStyledRow } from '../RestaurantsBar/style'
import { ContainerButtonModal } from '../RestaurantsSection/components/SearchInput/style'
import { ActiveFilters, Close, CustomNoResult, FilterButton, ModalTitleContainer, RestaurantContainer, StyledContainer, StyledRow } from '../RestaurantsSection/style'
import EventFilters from './components/eventFilters'
import EventItem from './components/eventItem'
import { EventsContainer } from './style'

interface RestaurantsSectionState {
  events: any[]
  eventTypes: any[]
  minDataToShow: []
  loading: boolean
  modalIsOpen: boolean
  filters: any[]
  filterValues: number[]
  path: string
}

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 101,
    padding: '0',
    backgroundColor: '#242424',
  },
}

const EventLogo = require('../../assets/images/general/Eventos.png')

Modal.setAppElement('#___gatsby')

class EventsSection extends Component<RouteComponentProps, any> {
  state: RestaurantsSectionState = {
    events: [],
    eventTypes: [],
    minDataToShow: [],
    loading: true,
    modalIsOpen: false,
    filters: [],
    filterValues: [],
    path: '',
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  componentDidMount() {
    this.getEventTypes()
    this.getEvents([])
  }

  getEvents = (inputFilter: number[]) => {
    axios.post(`${process.env.API_URL}events?per_page=500`, { eventTypes: inputFilter, startTime: moment().format('YYYY-MM-DD') }, {}).then((response: any) => {
      const hiddenEvents = [{ name: 'ikaro-21-cena' }, { name: 'ikaro-22-almuerzo' }, { name: 'ikaro-22-cena' }]
      const events = response.data.data.filter((eventItem: any) => {
        const event = hiddenEvents.find((hiddenEvent) => hiddenEvent.name === eventItem.slug)
        return event === undefined
      })

      this.setState({ events, loading: false })
    })
  }

  getEventTypes = async () => {
    await RequestGetter('get', 'eventTypes').then((data: any) => {
      this.setState({
        eventTypes: data.data,
        loading: false,
      })
    })
  }

  removeFilter(filterToRemove: any) {
    const { filters, filterValues } = this.state
    let currentFilters = filters.filter((filter) => filter !== filterToRemove)
    let currentFilterValues = filterValues.filter((filterValue) => filterValue !== this.retrieveEventTypeByName(filterToRemove))

    this.getEvents(currentFilterValues)

    this.setState({
      filters: currentFilters,
      filterValues: currentFilterValues,
    })
  }

  retrieveEventTypeByName(eventTypeName: string) {
    return this.state.eventTypes.filter((eventType: any) => eventType.event_type === eventTypeName)[0].id
  }

  applyFilters = (eventType: any, isChecked: boolean) => {
    const { filters, filterValues } = this.state
    if (!filters.includes(eventType.event_type)) {
      filters.push(eventType.event_type)
    }
    if (isChecked) {
      filterValues.push(eventType.id)
      this.setState({ filters, filterValues })
      this.getEvents(this.state.filterValues)
      return
    }

    for (let i = filterValues.length - 1; i >= 0; i--) {
      if (filterValues[i] === eventType.id) {
        filterValues.splice(i, 1)
      }
    }
    this.setState({
      filters: filters.filter((option) => option !== eventType.event_type),
      filterValues: filterValues,
    })
    this.getEvents(this.state.filterValues)
  }

  render() {
    const { events, loading, filters, eventTypes } = this.state

    return (
      <EventsContainer>
        <HelmetComponent title="Eventos - Grande Table" description="Disfruta de eventos gastronómicos únicos de la mano de los mejores chefs nacionales e internacionales." />
        <EventStyledRow>
          <div className="container-fluid">
            <div className="row">
              <EventTitleContainer className="container centered">
                <img src={EventLogo} />
              </EventTitleContainer>
            </div>
          </div>
        </EventStyledRow>
        <StyledContainer id="events-sec">
          {loading || !events ? (
            <Loading />
          ) : (
            <>
              <div className="container">
                <ContainerButtonModal onClick={this.openModal} className="container-btn-modal width-auto">
                  <div className="row">
                    <div className="filter-btn-txt">Mostrar Filtros</div>
                    <i className="icon-grande-table-20" />
                  </div>
                </ContainerButtonModal>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-md-3 display-desktop">
                    <EventFilters applyFilters={this.applyFilters} eventTypes={eventTypes} filters={filters} isModal={false} />
                  </div>
                  <div className="col-md-9">
                    {filters.length > 0 && (
                      <div className="row">
                        <ActiveFilters className="col-md-12">
                          {filters.map((option) => {
                            return (
                              <span key={option.id} onClick={() => this.removeFilter(option)}>
                                {option}
                                <i className="icon-grande-table-21" />
                              </span>
                            )
                          })}
                          <hr />
                        </ActiveFilters>
                      </div>
                    )}
                    {events.length < 1 ? (
                      <CustomNoResult className="col-md-12">No se encontraron resultados</CustomNoResult>
                    ) : (
                      <StyledRow className="row">
                        {events.map((event) => (
                          <EventItem key={`event-${event.id}`} event={event} />
                        ))}
                      </StyledRow>
                    )}
                  </div>
                </div>
              </div>
              <Modal isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal} style={customStyles}>
                <Close className="icon-grande-table-21" onClick={this.closeModal} />
                <div className="container-fluid">
                  <div className="row">
                    <ModalTitleContainer className="col-md-12">Filtrar</ModalTitleContainer>
                  </div>
                  <div className="row">
                    <RestaurantContainer className="col-md-12">
                      <EventFilters applyFilters={this.applyFilters} eventTypes={eventTypes} filters={filters} isModal={true} />
                    </RestaurantContainer>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <FilterButton onClick={this.closeModal}>Aplicar Filtros</FilterButton>
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </StyledContainer>
      </EventsContainer>
    )
  }
}

export default EventsSection
