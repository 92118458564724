import React, { Component } from 'react'
import Button from '../../../Button'
import { ModalContainer } from './style'

export default class StockModal extends Component<{ event: any; closeModal: any }, any> {
  constructor(props: any) {
    super(props)
  }

  render() {
    const { event, closeModal } = this.props
    return (
      <ModalContainer className="container">
        <div className="close-btn">
          <i className="icon-grande-table-21 close" onClick={closeModal} />
        </div>
        <div className="lbl-text roboto-bold header">¡Stock insuficiente!</div>
        <div className="roboto-light lbl-text padding-46 content">
          Lo sentimos el stock del evento se agotó. 
          Stock Actual: {event.total_tickets}
        </div>
        <Button className="btn-width" link={`events/${event.slug}`} callback={() => closeModal()}  text="Aceptar" type="primary" />
      </ModalContainer>
    )
  }
}
