import { RouteComponentProps } from '@reach/router'
import { withPrefix } from 'gatsby'
import React, { Component } from 'react'
import Modal from 'react-modal'
import { BurgerLogo, BurgerLogoMobile } from '../../assets/images/general'
import { RequestGetter } from '../../helpers/request'
import BurgerItem from '../BurgerFest/components/BurgerItem'
import { HelmetComponent } from '../HelmetComponent'
import Loading from '../Loading'
import { StyledRow as BurgerStyledRow, RestaurantContainer as BurgerTitleContainer } from '../RestaurantsBar/style'
import { StyledContainer, StyledRow } from '../RestaurantsSection/style'
import { BurgerContainer, StyledImg, StyledImgMobile, Title } from './style'

interface BurgerSectionState {
  restaurants: any[]
  cities: any[]
  specialities: []
  minDataToShow: number[]
  loading: boolean
  cityId: number
  modalIsOpen: boolean
  searchInput: string
  order: any
  filters: any[]
  total: number
  lastPage: number
  currentPage: number
}

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 101,
    padding: '0',
    backgroundColor: '#242424',
  },
}

Modal.setAppElement('#___gatsby')
let lastScrollY = 0
class BurgerSection extends Component<RouteComponentProps, BurgerSectionState> {
  constructor(props: RouteComponentProps) {
    super(props)
    this.state = {
      restaurants: [],
      cities: [],
      specialities: [],
      minDataToShow: [],
      loading: false,
      cityId: -1,
      modalIsOpen: false,
      searchInput: '',
      order: 'asc',
      filters: [],
      total: 0,
      lastPage: 0,
      currentPage: 0,
    }
    this.getRestaurants = this.getRestaurants.bind(this)
  }

  page = 1

  handleScroll = () => {
    lastScrollY = window.scrollY
    if (lastScrollY >= 1024 * this.page && this.page < this.state.lastPage) {
      this.page += 1
      this.getRestaurants(this.page)
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.getRestaurants()
    this.getCities().then((selectableCities) => {
      this.setState({ cities: selectableCities })
      this.getSpecialities()
    })
    window.addEventListener('scroll', this.handleScroll, true)
  }

  getCities = async (): Promise<any[]> => {
    return await RequestGetter('post', 'cities-by-module', { restaurant: true })
      .then((data: any) => data.data)
      .then((cities: any[]) => {
        let selectableCities: any[] = cities.map((city: any) => ({
          value: city.id,
          label: city.name,
        }))
        selectableCities = [...[{ value: '-1', label: 'Todas' }], ...selectableCities]
        return selectableCities
      })
  }

  getRestaurants = async (page = 1) => {
    const url = `restaurants/filter?page=${page}`
    this.setState({ loading: page === 1 })
    const data = await RequestGetter('get', url, null, {
      order: 'asc',
      filters: ['Burger Fest'],
    })
    data.data.forEach((restaurant: any) => {
      if (restaurant.pagination) {
        this.setState({
          total: restaurant.pagination[0].total,
          lastPage: restaurant.pagination[0].last_page,
          currentPage: restaurant.pagination[0].current_page,
        })
      }
    })
    this.setState(
      {
        restaurants: [...this.state.restaurants, ...data.data],
        loading: false,
      },
      () => history.pushState({}, 'Results for restaurants', this.generateUrlParams())
    )
  }

  generateUrlParams = (): string => {
    const { order, filters } = this.state
    let urlParams = ''
    let urlFilters = ''
    let urlOrder = ''
    if (filters && filters.length > 0) {
      urlFilters = `filters=${filters.join()}`
    }
    if (order) {
      urlOrder = `order=${order}`
    }
    urlParams = [urlFilters, urlOrder].filter(Boolean).join('&')
    return withPrefix(`/events/burger-fest?${urlParams}`)
  }

  getSpecialities = () => {
    RequestGetter('get', 'specialities').then((data: any) => {
      const specialities = data.data.filter((elem: any) => elem.id != 3)
      const filteringGroupsNumber = specialities.length
      this.setState({
        specialities,
        loading: false,
        minDataToShow: this.showCheckboxes(filteringGroupsNumber),
      })
    })
  }

  showCheckboxes = (filteringGroupsNumber: number): number[] => {
    let constantsArray: any[] = []
    for (let i = 0; i < filteringGroupsNumber; i++) {
      constantsArray.push(3)
    }
    return constantsArray
  }

  groupSpecialties = (specialties: any[] = []): any[] => {
    let groups: any[] = specialties.filter((elem) => elem.group_id != 3).map((elem) => elem['group'])
    let uniqueGroups = groups
      .map((elem) => elem['id'])
      .map((elem, index, final) => final.indexOf(elem) === index && index)
      .filter((elem: number) => groups[elem])
      .map((elem: number) => groups[elem])
    let currentGroups = uniqueGroups.map((group) => ({
      id: group.id,
      group: group.name,
      icon: this.showIcon(group.name),
      categories: this.concatenateCategories(specialties, group.id),
    }))
    return currentGroups
  }

  showIcon = (groupName: string): string => {
    switch (groupName) {
      case 'Tipo de Cocina / Nacionalidad':
        return 'icon-cubiertos'
      case 'Momentos Grande Table':
        return 'icon-copas'
      case 'Especialidad':
        return 'icon-chef'
      default:
        return ''
    }
  }

  concatenateCategories = (specialties: any[], groupId: number): string => {
    let categories = ''
    specialties.forEach((specialty: any) => {
      if (groupId == specialty.group_id) {
        categories = categories + ', ' + specialty.name
      }
    })
    categories = categories.substring(2, categories.length)
    return categories
  }

  render() {
    const { restaurants, loading, total } = this.state
    return (
      <BurgerContainer>
        <HelmetComponent title="Eventos - Grande Table" description="Disfruta de eventos gastronómicos únicos de la mano de los mejores chefs nacionales e internacionales." />
        <BurgerStyledRow>
          <div className="container-fluid">
            <BurgerTitleContainer className="centered">
              <Title>
                <StyledImg className="event-banner" src={BurgerLogo} />
              </Title>
              <StyledImgMobile className="event-banner" src={BurgerLogoMobile} />
            </BurgerTitleContainer>
          </div>
        </BurgerStyledRow>
        <StyledContainer id="burger-sec">
          <div className="container">
            <div className="row">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className="col-md-1" />
                  <div className="col-md-10">
                    {restaurants && total >= 1 && (
                      <>
                        <h3>{total} RESTAURANTES</h3>
                        <h3>Disponibles</h3>
                        <StyledRow className="row">
                          {restaurants.map((restaurant) => {
                            if (!restaurant.pagination) {
                              let specialties = this.groupSpecialties(restaurant.specialities)
                              return (
                                <BurgerItem
                                  key={`burger-fest-${restaurant.id}`}
                                  image={restaurant.bg_image}
                                  icon={restaurant.icon}
                                  title={restaurant.name}
                                  slug={restaurant.slug}
                                  specialties={specialties}
                                  hasEveve={restaurant.eveve}
                                />
                              )
                            }
                          })}
                        </StyledRow>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </StyledContainer>
      </BurgerContainer>
    )
  }
}

export default BurgerSection
