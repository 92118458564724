type HelperProps = {
  currentItems: []
  eventId: number
  itemType: string
}
export const purchasedTickets = ({ currentItems, eventId, itemType }: HelperProps) =>
  currentItems
    .filter((item: any) => item.item_type_id === eventId && item.item_type === itemType)
    .map((item: any) => item.quantity)
    .reduce((a: number, b: number) => a + b, 0)

type AvailableTicketsProps = {
  purchase: any
  eventTickets: number
  eventId: number
  itemType: string
}
export const calculateAvailableTickets = ({
  purchase,
  eventTickets,
  eventId,
  itemType,
}: AvailableTicketsProps): number => {
  if (!purchase) {
    return eventTickets
  }

  return eventTickets - purchasedTickets({ currentItems: purchase.items, eventId, itemType })
}
