import styled from 'styled-components'
import LazyImage from '../../../LazyImage'

export const EventItemContainer = styled.div`
  .unavailable-text {
    position: absolute;
    top: 50%;
    padding-right: 3rem;
    color: #ffffff;
    font-family: DroidSerif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: center;
    &.hide-text {
      display: none;
    }
  }
`

export const StyledCard = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 400px;

  &.active-opacity {
    opacity: 0.4;
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media (max-width : 414px) {
    height: 430px;
  }

`
export const StyledWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.12);
  
  padding: 1rem;
  height: 100%;
  border-radius: 5px;
`
export const StyledImageContainer = styled.div`
  .eveve-gift {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #c59d5f;
    color: #ffffff;
    font-size: 22px;
    font-family: Roboto;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    position: absolute;
    left: 79%;
    top: 18px;
    &.hide {
      display: none;
    }
  }
`

export const StyledImage = styled(LazyImage)`
  max-width: 100%;
  margin-top: -15%;
`
export const StyledBody = styled.div`
  display: flex;
  margin-top: 0.5rem;
  .no-tickets-link {
    cursor: default;
    &:hover {
      background: transparent;
      color: white;
    }
  }
`

export const LogoContainer = styled.div`
  flex: 1 0 35%;
`

export const StyledLogo = styled(LazyImage)``

export const ButtonContainer = styled.div`
  position: absolute;
  top: 85%;
  margin-right: 20%;
  left: 0%;
  align-items: center;
  display: flex;
  padding: 0 0.5rem;
  width: 100%;
`
export const LogoAndNameContainer = styled.div`
  padding-top: 1rem;
  .title-logo {
    align-items: center;
    &.cat-grp {
      min-height: 94px;
    }
  }
`
export const TitleContainer = styled.span`
  width: 65%;
  text-transform: capitalize;
  font-weight: 900;
  padding-left: 0 !important;
  padding-right: 8px !important;
`

export const SpecialityContainer = styled.div`
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 1px;
  text-align: left;
  color: #ffffff;
  align-items: center;
  i.icon-grande-table-15::before {
    margin-left: 0;
    float: left;
  }
  .category-container {
    margin: 0.35rem 0.75rem 0.5rem 0;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    div {
      margin-left: 0.35rem;
    }
  }
`
