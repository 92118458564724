import styled from 'styled-components'
import theme from '../../../styles/theme'

export const NumericInputContainer = styled.div`
  .react-numeric-input {
    input {
      width: 90px;
      height: 31px;
      border: 1px solid ${theme.purpleBrown} !important;
      border-radius: 0 !important;
    }
    b {
      border-radius: 0 !important;
      background-color: ${theme.purpleBrown} !important;
      top: 0 !important;
      bottom: 0 !important;
      width: 4ex !important;
      i {
        background-color: #ffffff !important;
      }
      &:nth-of-type(2) {
        left: 0 !important;
      }
      &:nth-of-type(1) {
        right: 0 !important;
      }
    }
  }
`
