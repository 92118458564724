import { RouteComponentProps } from '@reach/router'
import axios from 'axios'
import { navigate } from 'gatsby'
import moment from 'moment'
import React, { Component } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'
import { toast, ToastContainer } from 'react-toastify'
import AuthContext from '../../context/auth-context'
import { GtmEventsLoad } from '../../helpers/gtm'
import { EventAction } from '../../helpers/GTM/EventAction'
import Button from '../Button'
import { calculateAvailableTickets, purchasedTickets } from '../Cart/helper'
import Map from '../Event/components/Map'
import ParticipantDetail from '../Event/components/Participant'
import { LinkContainer } from '../EventsSection/components/style'
import IncreaseDecreaseNumber from '../Generics/IncreaseDecreaseNumber'
import Loading from '../Loading'
import { ButtonContainer } from '../RestaurantsSection/components/RestaurantItem/style'
import RestaurantSlider from '../RestaurantsSection/components/RestaurantSlider'
import StockModal from './components/Modals/StockModal'
import {
  EventBottomBackground,
  EventDetails,
  EventImage,
  EventTopBackground,
  MapLinkContainer,
  Separator,
  SponsorsContainer,
  StyledContainer,
  StyledContainerMap,
  StyledRow,
} from './style'

type SubEvent = {
  available_stock: number
  date_end: Date
  date_start: Date
  event_id: number
  id: number
  schedule_type: string
  status: string
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingLeft: 0,
    paddingRight: 0,
    width: '560px',
    textAlign: 'center',
  },
}

const scheduleType: { [key: string]: string } = {
  Breakfast: 'Desayuno',
  Lunch: 'Almuerzo',
  Snack: 'Merienda',
  Dinner: 'Cena',
}

class Event extends Component<RouteComponentProps<{ slug: string }>, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      event: {
        id: 0,
        title: '',
        description: '',
        event_date: '',
        start_time: '',
        end_time: '',
        address: '',
        phone: '',
        place: '',
        addons: '',
        regular_price: '',
        grande_table_price: 0,
        total_tickets: 0,
        terms_conditions: '',
        latitude: 0,
        longitude: 0,
        centerMap: {
          lat: -0.182184,
          lng: -78.488111,
        },
        currentPosition: {
          lat: 0,
          lng: 0,
        },
        zoom: 13,
        city_id: 0,
        city_name: '',
        eventtype_id: 0,
        event_type: '',
        logo: '',
        image: '',
        sponsors: [],
        subEvents: [],
        schedule: null,
        eventDate: null,
      },
      terms: false,
      purchaseItem: { quantity: 1 },
      showModal: false,
      loading: true,
    }
    this.transferItemsToCart.bind(this)
    this.handleDate.bind(this)
  }

  changeEvent = (slug: string) => {
    this.getEvent(slug || '')
  }

  componentDidMount() {
    this.getEvent(this.props.slug || '')
  }

  getEvent = (slug: string) =>
    axios
      .get(`${process.env.API_URL}events/${slug}`)
      .then((data: any) => data.data)
      .then((response: any) => {
        this.setState({
          event: {
            id: response.id,
            title: response.title,
            description: response.description,
            event_date: response.event_date,
            start_time: response.start_time,
            end_time: response.end_time,
            address: response.address,
            phone: response.phone,
            place: response.place,
            addons: response.addons,
            regular_price: response.regular_price,
            grande_table_price: response.grande_table_price,
            total_tickets: response.total_tickets,
            terms_conditions: response.terms_conditions,
            latitude: response.latitude,
            longitude: response.longitude,
            city_id: response.city_id,
            city_name: response.city.name,
            eventtype_id: response.eventtype_id,
            event_type: response.event_type.event_type,
            logo: response.logo,
            image: response.image,
            sponsors: response.sponsors,
            restaurants: response.restaurants,
            chefs: response.chefs,
            subEvents: response.sub_events,
            youtube: response.youtube ? `https://www.youtube.com/embed/${response.youtube.substring(17, 50)}` : null,
          },
          loading: false,
        })
      })

  handleClick = (latitude: number, longitude: number) => {
    this.setState({
      event: {
        ...this.state.event,
        latitude,
        longitude,
        zoom: 16,
      },
    })
  }

  handleTerms = () => {
    this.setState({ terms: !this.state.terms })
  }

  handleClickToMap = (e: any) => {
    const { event } = this.state
    e.preventDefault()
    let urlToMap = 'http://maps.google.com/maps?q=' + event.latitude + ',' + event.longitude
    window.open(urlToMap)
  }

  handleDate = (e: { value: string; label: string }) => {
    this.setState({
      event: {
        ...this.state.event,
        eventDate: e,
      },
    })
  }

  handleSchedule = (e: { value: {}; label: string }) => {
    this.setState({
      event: {
        ...this.state.event,
        schedule: e,
        total_tickets: parseInt(e.value.tickets),
      },
    })
  }

  obtainTickets = (item: any) => this.setState({ purchaseItem: { quantity: item.quantity ? item.quantity : 1 } })

  transferItemsToCart() {
    const { purchase } = this.context

    const { event } = this.state

    if (!event.eventDate || !event.schedule) {
      toast.error('No has seleccionado un horario')
      return
    }

    if (purchase) {
      this.addTicketsToCart()
    } else {
      this.generateNewPurchase()
    }
    this.executeEventForGTM()
  }

  async updateEventStock(eventId: any) {
    await axios
      .get(`${process.env.API_URL}event/${eventId}`)
      .then((data: any) => data.data)
      .then((response: any) => {
        this.setState({ event: { ...this.state.event, total_tickets: response.total_tickets } })
      })
  }

  async addTicketsToCart() {
    const { user, getPurchase, setPurchase } = this.context
    const headers = {
      'Content-Type': 'application/json',
    }

    const { event, purchaseItem } = this.state
    const purchase = getPurchase()
    const subtotals = this.getSubTotals(event, purchase, purchaseItem.quantity)
    const purchaseData = {
      id: purchase.id,
      customerId: user ? user.customerId : null,
      grande_table_subtotal: subtotals.grande_table_subtotal,
      subtotal: subtotals.subtotal,
      items: [
        {
          id: this.retrieveItemId(event.id),
          description: event.title,
          quantity: this.updateQuantity(purchaseItem.quantity, event.id),
          unit_price: event.regular_price == 0 ? event.grande_table_price : event.regular_price,
          grande_table_price: event.grande_table_price,
          item_type: 'event',
          item_type_id: event.id,
          date: event.eventDate.value,
          sub_event_id: event.schedule.value.id,
        },
      ],
    }

    await axios
      .post(`${process.env.API_URL}purchase/edit`, purchaseData, { headers })
      .then((response: any) => response.data)
      .then((purchaseResponse: any) => setPurchase(purchaseResponse))
      .then(() => navigate('/cart'))
      .catch(() => {
        this.setState({ showModal: true })
        this.updateEventStock(event.id)
      })
  }

  retrieveItemId = (eventId: number): number | null => {
    const purchasedItem = this.getPurchasedItem(eventId)

    if (!purchasedItem) {
      return null
    }

    return purchasedItem.id
  }

  getSubTotals = (event: any, purchase: any, quantity: number) => {
    return {
      grande_table_subtotal: purchase.grande_table_subtotal + parseFloat(event.grande_table_price) * quantity,
      subtotal: purchase.subtotal + parseFloat(event.regular_price) * quantity,
    }
  }

  updateQuantity = (quantity: number, eventId: number): number => {
    const purchasedItem = this.getPurchasedItem(eventId)

    if (!purchasedItem) {
      return quantity
    }

    return quantity + purchasedItem.quantity
  }

  getPurchasedItem = (eventId: number) => {
    const { purchase } = this.context
    const purchasedItem = purchase.items.filter((purchasedItem: any) => purchasedItem.item_type_id === eventId && purchasedItem.item_type === 'Evento')[0]
    return purchasedItem
  }

  async generateNewPurchase() {
    const { user, setPurchase } = this.context
    const headers = {
      'Content-Type': 'application/json',
    }

    const { event, purchaseItem } = this.state
    const purchaseData = {
      customerId: user ? user.customerId : null,
      grande_table_subtotal: event.grande_table_price * purchaseItem.quantity,
      subtotal: event.regular_price * purchaseItem.quantity,
      items: [
        {
          description: event.title,
          quantity: purchaseItem.quantity,
          unit_price: event.regular_price == 0 ? event.grande_table_price : event.regular_price,
          grande_table_price: event.grande_table_price,
          item_type: 'event',
          item_type_id: event.id,
          date: event.eventDate.value,
          sub_event_id: event.schedule.value.id,
        },
      ],
    }

    await axios
      .post(`${process.env.API_URL}purchase/new`, purchaseData, { headers })
      .then((response: any) => response.data)
      .then((purchase: any) => setPurchase(purchase))
      .then(() => navigate('/cart'))
      .catch(() => {
        this.setState({ showModal: true })
        this.updateEventStock(event.id)
      })
  }

  closeModal = () => this.setState({ showModal: false })

  executeEventForGTM = () => {
    const { event, purchaseItem } = this.state
    GtmEventsLoad(new EventAction('addToCart', 'add', 'event').initialize({ eventData: event, quantity: purchaseItem.quantity }))
  }

  formattedNumber = (numberValue: any) => {
    return numberValue ? Number(numberValue).toFixed(2).replace('.', ',') : '0,00'
  }

  isAvailable = (eventTickets: number): boolean => {
    const { purchase } = this.context

    if (!purchase) {
      return true
    }

    const eventPurchasedTickets = purchasedTickets({
      currentItems: purchase.items,
      eventId: this.state.event.id,
      itemType: 'Evento',
    })
    return eventPurchasedTickets < eventTickets
  }

  setOptions = (subEventData: SubEvent[]) => {
    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' }
    const filteredDates = subEventData.filter((subEventItem: SubEvent) => new Date(subEventItem.date_end) > new Date())
    const activeDates = filteredDates.filter((a) => a.status == 'active')
    const finalDates = this.removeDuplicates(activeDates)
    const dateValues = finalDates.map((dateItem: SubEvent) => ({ value: dateItem.date_start, label: new Date(dateItem.date_start).toLocaleDateString('es-EC', options) }))
    return { date: dateValues }
  }

  setScheduleOptions = (optionSelected: string) => {
    const { event } = this.state
    const filteredSubEvents = event.subEvents.filter((subEventItem: SubEvent) => subEventItem.date_start.toString().substring(0, 10) === optionSelected.substring(0, 10))

    const activeSubEvents = filteredSubEvents.filter((subEventItem: SubEvent) => subEventItem.status === 'active')

    return activeSubEvents.map((subEventItem: SubEvent) => ({
      value: { id: subEventItem.id, start: subEventItem.date_start, end: subEventItem.date_end, tickets: subEventItem.available_stock },
      label: `${subEventItem.date_start.toString().substring(10, 16)} - ${subEventItem.date_end.toString().substring(10, 16)}`,
    }))
  }

  removeDuplicates = (subEvents: SubEvent[]) => {
    let uniqueEvents = []
    let eventTempStructure = {}

    for (let i in subEvents) {
      eventTempStructure[subEvents[i].date_start.toString().substring(0, 10)] = subEvents[i]
    }

    for (let i in eventTempStructure) {
      uniqueEvents.push(eventTempStructure[i])
    }

    return uniqueEvents
  }

  render() {
    const { purchase } = this.context
    const { event, loading, purchaseItem, showModal } = this.state
    const options = this.setOptions(event.subEvents)

    return loading === true ? (
      <Loading />
    ) : (
      <React.Fragment>
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick rtl={false} draggable={false} pauseOnHover />
        <StyledContainer className="container-fluid">
          <StyledRow className="row">
            <EventTopBackground>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 flex">
                        <EventImage>
                          <div className="box">
                            <img src={event.image} alt={event.title + '-Grande Table'} className="image" />
                          </div>
                        </EventImage>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <EventDetails>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="title">{event.title}</div>
                            </div>
                            {event.grande_table_price && event.regular_price != 0 ? (
                              <div className="col-md-3">
                                <div className="row flex-container">
                                  <div className="flex-items">
                                    <div className="price ">${this.formattedNumber(event.regular_price)}</div>
                                    <div className="price-title">Socio Diners Club</div>
                                  </div>
                                  <div className="flex-items">
                                    <Separator />
                                  </div>
                                  <div className="flex-items">
                                    <div className="price">${this.formattedNumber(event.grande_table_price)}</div>
                                    <div className="price-title">Socio Grande Table</div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="col-md-3">
                                <div className="row flex-container offset-md-11">
                                  <div className="flex-items">
                                    <div className="price ">${this.formattedNumber(event.grande_table_price)}</div>
                                    <div className="price-title">Precio Grande Table</div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="event-type">{event.event_type}</div>
                          <div className="event-description">{event.description}</div>
                          <div className="row block data-block date-place">
                            <div className="row col-sm-12 col-md-3 no-margins bottom-padding">
                              <i className="icon-ciudad item-space" />
                              <div className="item-space">Ciudad:</div>
                              <div>{event.city_name}</div>
                            </div>
                          </div>
                          <div className="row block data-block">
                            <div className="row col-12 no-margins bottom-padding">
                              <i className="icon-grande-table-26 item-space" />
                              <div className="item-space">Dirección:</div>
                              <div>{event.address}</div>
                            </div>
                            <div className="row col-12 no-margins bottom-padding">
                              <i className="icon-grande-table-27 item-space" />
                              <div className="item-space">Teléfonos:</div>
                              <div>{event.phone}</div>
                            </div>
                            <div className="row col-12 no-margins bottom-padding">
                              <i className="icon-lugar item-space" />
                              <div className="item-space">Lugar:</div>
                              <div>{event.place}</div>
                            </div>
                            <div className="row col-12 no-margins top-padding">
                              <div>
                                <strong className="item-space bold-text">Incluye:</strong>
                                {event.addons}
                              </div>
                            </div>
                          </div>
                          {event.total_tickets == 0 ? (
                            <div className="row block ">
                              <div className="col-md-12 col-sm-12 bottom-padding bottom-padding-more row-on-small">
                                <ButtonContainer>
                                  <LinkContainer className="sold-out" type="secondary">
                                    Evento agotado
                                  </LinkContainer>
                                </ButtonContainer>
                              </div>
                            </div>
                          ) : (
                            <div className="row block">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="quantity">
                                    <div className="bold-text">Fecha:</div>
                                    <div className="col-12">
                                      <Select
                                        onChange={this.handleDate}
                                        options={options.date}
                                        value={event.eventDate}
                                        styles={{
                                          dropdownIndicator: (defaultStyles) => ({
                                            ...defaultStyles,
                                            color: 'white',
                                            background: 'black',
                                          }),
                                          control: (base, state) => ({
                                            ...base,
                                            border: '1px solid black',
                                            borderRadius: '0 !important',
                                            boxShadow: 'none',
                                            '&:hover': {
                                              border: '1px solid black',
                                            },
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="quantity">
                                    <div className="bold-text">Horarios:</div>
                                    <div className="col-12">
                                      <Select
                                        onChange={this.handleSchedule}
                                        options={this.setScheduleOptions(event.eventDate ? event.eventDate.value : '')}
                                        value={event.schedule}
                                        styles={{
                                          dropdownIndicator: (defaultStyles) => ({
                                            ...defaultStyles,
                                            color: 'white',
                                            background: 'black',
                                          }),
                                          control: (base, state) => ({
                                            ...base,
                                            border: '1px solid black',
                                            borderRadius: '0 !important',
                                            boxShadow: 'none',
                                            '&:hover': {
                                              border: '1px solid black',
                                            },
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="quantity">
                                    <div className="bold-text">No. de tickets:</div>
                                    <div className="col-12">
                                      <IncreaseDecreaseNumber
                                        item={purchaseItem}
                                        callback={() => this.obtainTickets(purchaseItem)}
                                        availableTickets={calculateAvailableTickets({
                                          purchase,
                                          eventTickets: event.total_tickets,
                                          eventId: this.state.event.id,
                                          itemType: 'Evento',
                                        })}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="buy-button">
                                    <Button id="GTABLE_EVENT_CART" link="cart" text="RESERVAR EVENTO" callback={() => this.transferItemsToCart()} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </EventDetails>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </EventTopBackground>
            <EventBottomBackground>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <SponsorsContainer className={`${!event.sponsors || event.sponsors.length == 0 ? 'hide-container' : ''}`}>
                      <div className="sponsors-title">Auspiciantes</div>
                      <div className="sponsors-bar">
                        <div className="row">
                          {event.sponsors &&
                            event.sponsors.length > 0 &&
                            event.sponsors.map((sponsor: any) => (
                              <div className="col" key={sponsor.id}>
                                <img src={sponsor.image} alt={sponsor.sponsor_name} style={{ maxWidth: '100%' }} />
                              </div>
                            ))}
                        </div>
                      </div>
                    </SponsorsContainer>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <SponsorsContainer>
                      <div className="sponsors-title">CONOCE MÁS SOBRE NUESTROS CHEFS Y RESTAURANTES</div>

                      {event.restaurants &&
                        event.restaurants.map((restaurant: any) => (
                          <ParticipantDetail
                            name={restaurant.name}
                            category={restaurant.category}
                            logo={restaurant.logo}
                            description={restaurant.description}
                            social={JSON.parse(restaurant.social_media)}
                            event={event.title}
                          />
                        ))}

                      {event.chefs &&
                        event.chefs.map((chef: any) => (
                          <ParticipantDetail
                            name={chef.name}
                            restaurant={chef.restaurant}
                            logo={chef.logo}
                            description={chef.description}
                            social={JSON.parse(chef.social_media)}
                            event={event.title}
                          />
                        ))}
                    </SponsorsContainer>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <SponsorsContainer>
                      <div className="sponsors-title">DESCUBRE MÁS</div>
                      {event.youtube && <div className="map-title">Nuestros Íconos Gastronómicos</div>}
                      {event.youtube && <iframe id="ytplayer" width="100%" height="300" src={event.youtube}></iframe>}
                      <div className="map-title">Encuéntranos</div>
                      <Map
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.API_GOOGLE}&v=3.exp&libraries=geometry,drawing,places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<StyledContainerMap />}
                        mapElement={<div style={{ height: `100%` }} />}
                        zoom={event.zoom ? event.zoom : 13}
                        handleClick={this.handleClick}
                        latitude={event.latitude}
                        longitude={event.longitude}
                      />
                      <MapLinkContainer onClick={this.handleClickToMap}>
                        <i className="icon-grande-table-26" style={{ fontSize: '21px' }} />
                        Ver en el mapa
                      </MapLinkContainer>
                    </SponsorsContainer>
                    <SponsorsContainer>
                      <div className="sponsors-title">
                        TÉRMINOS Y CONDICIONES
                        <i
                          style={{ cursor: 'pointer' }}
                          className={this.state.terms ? 'icon-grande-table-32 item-space' : 'icon-grande-table-10 item-space'}
                          onClick={this.handleTerms}
                        />
                      </div>
                      {this.state.terms && <div className="terms-bar">{event.terms_conditions}</div>}
                    </SponsorsContainer>
                  </div>
                </div>
              </div>
            </EventBottomBackground>
            <RestaurantSlider type={'events'} onChangeEvent={this.changeEvent} eventId={event.id} />
            <Modal isOpen={showModal} onRequestClose={() => this.closeModal} style={customStyles}>
              <StockModal event={event} closeModal={this.closeModal} />
            </Modal>
          </StyledRow>
        </StyledContainer>
      </React.Fragment>
    )
  }
}
Event.contextType = AuthContext

export default Event
