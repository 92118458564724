import React, { Component } from 'react'
import * as NumericInput from 'react-numeric-input'
import { NumericInputContainer } from './style'

class IncreaseDecreaseNumber extends Component<{
  item: any
  callback: Function
  availableTickets: number
}> {
  constructor(props: any) {
    super(props)
  }

  componentDidMount() {
    document.getElementById('numeric-input')?.setAttribute('readonly', 'true')
  }

  onChange = (value: number) => {
    const { item, callback } = this.props
    item.quantity = value
    callback(item)
  }

  render() {
    const { availableTickets, item } = this.props

    return (
      <NumericInputContainer>
        <NumericInput
          id="numeric-input"
          componentClass="input"
          mobile
          min={1}
          max={availableTickets >= 10 ? 10 : availableTickets}
          value={item.quantity}
          onChange={(value) => value && this.onChange(value)}
          strict={true}
        />
      </NumericInputContainer>
    )
  }
}

export default IncreaseDecreaseNumber
