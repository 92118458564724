import React from 'react'
import { withScriptjs, withGoogleMap, Marker } from 'react-google-maps'

import { StyledGoogleMaps } from './style'

interface Props {
  zoom: any
  handleClick: Function
  latitude: number
  longitude: number
}

const Map = withScriptjs(
  withGoogleMap(({ zoom, handleClick, latitude, longitude }: Props) => (
    <StyledGoogleMaps
      defaultZoom={8}
      defaultCenter={{ lat: Number(latitude), lng: Number(longitude) }}
      zoom={zoom}
    >
      <Marker
        position={{ lat: Number(latitude), lng: Number(longitude) }}
        onClick={() => handleClick(Number(latitude), Number(longitude))}
      />
    </StyledGoogleMaps>
  ))
)

export default Map
