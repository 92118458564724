import React from 'react'
import Select from 'react-select'
import Modal from 'react-modal'
import {
  StyledRow,
  StyledContainer,
  StyledRowSecondary,
  StyledContainerMap,
  CustomPanel,
  MapLinkContainer,
  CustomTr,
  CustomTable,
  ViewMore,
  InfoContainer
} from './style'
import Office from './components/Office'
import Map from './components/Map'
import Reservation from './components/Reservation'
import { CustomButton, ButtonContainer } from './style'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'moment/locale/es'
import Identification from './components/Reservation/Modals/Identification'
import { ModalContainer } from './components/Reservation/style'
import { ModalContainerProcess } from './components/Reservation/Modals/style'
import Eveve from './components/Reservation/Modals/Eveve'
import ConfirmReservation from './components/Reservation/Confirmation'
import Membership from './components/Reservation/Modals/Membership'
import { GtmEventsLoad } from '../../helpers/gtm'
import { ReservationEvent } from '../../helpers/GTM/ReservationEvent'
import pixelFacebookStructure from '../../helpers/pixelFacebookStructure'

const WebIcon = require('../../assets/images/general/web-icon.svg')

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingLeft: 0,
    paddingRight: 0
  }
}

const reservationModalStyles = {
  content: { ...customStyles.content, paddingBottom: 0 }
}

const confirmationModalStyles = {
  content: { ...customStyles.content, width: '560px' }
}

const modalIdentificationStyles = {
  content: { ...customStyles.content, width: '750px' }
}

const membershipModalStyles = {
  content: { ...customStyles.content, width: '600px' }
}

interface RestaurantProps {
  restaurant: any
}

const infoReservation = {
  type: 'booking',
  description: 'Reserva de mesa preferencial.'
}

Modal.setAppElement('#___gatsby')

class RestaurantProfile extends React.Component<RestaurantProps, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      branch:
        this.props.restaurant.subsidiaries.length >= 1
          ? this.props.restaurant.subsidiaries[0]
          : null,
      zoom: 13,
      centerMap: {
        lat: -0.182184,
        lng: -78.488111
      },
      currentPosition: {
        lat: 0,
        lng: 0
      },
      displaySchedule: false,
      selectableBranches: this.loadSelectableSubsidiaries(),
      isModalOpened: false,
      isIdentificationModalOpened: false,
      isEveveModalOpened: false,
      isConfirmationModalOpened: false,
      isMembershipModalOpened: false,
      customerId: null,
      identification: '',
      hasOptions: false
    }
    this.getSubsidiary = this.getSubsidiary.bind(this)
    this.props.restaurant.benefits.push(infoReservation)
  }

  componentDidMount() {
    if (sessionStorage.getItem('user')) {
      const loggedUser = JSON.parse(sessionStorage.getItem('user') || '')
      if (loggedUser.customerId) {
        this.setState({
          customerId: loggedUser.customerId
        })
      }
    }

    const defaultCenter = {
      lat: Number(this.state.branch.latitude),
      lng: Number(this.state.branch.longitude)
    }
    this.setState({
      centerMap: defaultCenter,
      currentPosition: defaultCenter
    })
  }

  getRestrictions(): any []{
    return this.props.restaurant.restrictions.map((restriction: any) => ({
      type: null,
      description: restriction
    }))
  }

  loadSelectableSubsidiaries(): any[] {
    return this.props.restaurant.subsidiaries.map((subsidiary: any) => ({
      value: subsidiary.id,
      label: subsidiary.alias
    }))
  }

  handleClick = (latitude: number, longitude: number) => {
    this.setState({
      centerMap: {
        lat: Number(latitude),
        lng: Number(longitude)
      },
      zoom: 16
    })
  }

  handleSelect = (selectedOptions: any) => {
    this.setState({
      branch: this.props.restaurant.subsidiaries.filter(
        (subsidiary: any) => subsidiary.id === selectedOptions.value
      )[0]
    })
  }

  display = () => {
    this.setState({ displaySchedule: !this.state.displaySchedule })
  }

  getSubsidiary(subsidiary: any) {
    this.setState({ branch: subsidiary })
  }

  handleClickToMap = (e: any) => {
    e.preventDefault()
    let urlToMap =
      'http://maps.google.com/maps?q=' +
      this.state.branch.latitude +
      ',' +
      this.state.branch.longitude
    window.open(urlToMap)
  }

  checkEveveRestaurant = () => {
    const { restaurant } = this.props
    GtmEventsLoad(new ReservationEvent('addToCart', 'add', 'reservation').initialize({item: restaurant}))
    if (restaurant.eveve) {
      this.openEveveModal()
      return
    }
    this.openIdentificationModal()
  }

  openEveveModal = () => {
    this.setState({ isEveveModalOpened: true })
  }

  openModal = () => {
    this.setState({ isModalOpened: true })
  }

  closeModal = (closingType: string) => {
    switch (closingType) {
      case 'normal':
        this.setState({
          isModalOpened: false
        })
        return
      case 'confirmation':
        this.setState({
          isModalOpened: false,
          isConfirmationModalOpened: true
        })
    }
  }

  openIdentificationModal = () => {
    this.setState({ isIdentificationModalOpened: true })
  }

  closeIdentificationModal = (closingType: string) => {
    switch (closingType) {
      case 'normal':
        this.setState({
          isIdentificationModalOpened: false
        })
        return
      case 'reservation':
        this.setState({
          isIdentificationModalOpened: false,
          isModalOpened: true
        })
        return
      case 'eveve':
        this.setState({
          isIdentificationModalOpened: false,
          isEveveModalOpened: true
        })
        return
      case 'membership-options':
        this.setState({
          isIdentificationModalOpened: false,
          isMembershipModalOpened: true,
          hasOptions: true
        })
        return
      case 'membership':
        this.setState({
          isIdentificationModalOpened: false,
          isMembershipModalOpened: true,
          hasOptions: false
        })
        return
    }
  }

  closeEveveModal = () => {
    this.setState({ isEveveModalOpened: false })
  }

  closeConfirmationModal = () => {
    this.setState({ isConfirmationModalOpened: false })
  }

  closeMembershipModal = (closingType: string) => {
    switch (closingType) {
      case 'normal':
        this.setState({ isMembershipModalOpened: false })
        return
      case 'eveve':
        this.setState({ isMembershipModalOpened: false })
        this.checkEveveRestaurant()
        break
    }
  }

  retrieveCustomerId = (customerId: number) => {
    this.setState({ customerId })
  }

  retrieveIdentification = (identification: string) => {
    this.setState({ identification })
  }

  retrieveFields = (fields: any) => {
    this.setState({ fields })
  }

  render() {
    const { restaurant } = this.props
    const {
      zoom,
      centerMap,
      currentPosition,
      branch,
      selectableBranches,
      customerId,
      identification,
      fields,
      isModalOpened,
      isIdentificationModalOpened,
      isEveveModalOpened,
      isConfirmationModalOpened,
      isMembershipModalOpened,
      hasOptions
    } = this.state
    let description = null

    const scheduleRange = branch.schedule.week.map((scheduleItem: any, key: number) => (
      <CustomTr key={key}>
        <td className="first-td">
          <strong>{scheduleItem.day}</strong>
        </td>
        <td>{scheduleItem.schedule}</td>
      </CustomTr>
    ))

    if (restaurant.description !== '' && restaurant.description !== null) {
      description = restaurant.description
    }
    let speciality = restaurant.categories
      ? restaurant.categories.map((category: any) => category.name).join(', ')
      : ''

    const ReactPixel =  require('react-facebook-pixel')
    ReactPixel.default.init(process.env.FACEBOOK_PIXEL_ID)
    const PixelFacebookStructure = pixelFacebookStructure({restaurant,branch,currentPosition,speciality})
    ReactPixel.default.pageView()

    return (
      <StyledContainer className="container">
        <script type="application/ld+json">
          {PixelFacebookStructure}
        </script>
        <StyledRow className="row">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-lg-9">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <Office
                        logo={restaurant.icon_b}
                        branches={restaurant.subsidiaries}
                        description={description}
                        descriptionFest={restaurant.altern_description}
                        nameFest= {restaurant.altern_name}
                        benefits={restaurant.benefits}
                        restrictions={this.getRestrictions()}
                        getActualBranch={this.getSubsidiary}
                        restaurantName={restaurant.name}
                        speciality={speciality}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <ButtonContainer>
                        <CustomButton
                          id="GTABLE_REST_RESERVA"
                          data-store={restaurant.name}
                          onClick={this.checkEveveRestaurant}
                        >
                          <i className="icon-grande-table-24" />
                          <span>Quiero Reservar</span>
                        </CustomButton>
                      </ButtonContainer>
                    </div>
                  </div>
                  {this.state.branch && (
                    <div className="row">
                      <div className="col-lg-12">
                        <CustomPanel>
                          {restaurant.subsidiaries.length > 1 && (
                            <Select
                              isSearchable={false}
                              defaultValue={selectableBranches[0]}
                              className="select-control-container"
                              classNamePrefix="select-control"
                              onChange={this.handleSelect}
                              options={selectableBranches}
                            />
                          )}
                          {restaurant.subsidiaries.length == 1 && (
                            <div className="panel-heading">Información</div>
                          )}
                          <div className="panel-body">
                            <InfoContainer>
                              <div className="title-info">
                                <i className="icon-grande-table-26" />
                                Dirección:
                              </div>
                              <div className="item-info">
                                <p>{branch.address}</p>
                              </div>
                            </InfoContainer>
                            <InfoContainer>
                              <div className="title-info">
                                <i className="icon-grande-table-27" />
                                Contacto:
                              </div>
                              <div className="item-info">
                                <a href={'tel:' + branch.phone}>{branch.phone} </a>
                              </div>
                            </InfoContainer>
                            {restaurant.website && (
                              <InfoContainer>
                                <div className="title-info">
                                  <img src={WebIcon} />
                                  <a href={restaurant.website} target="_blank">
                                    Visita la web aquí
                                  </a>
                                </div>
                              </InfoContainer>
                            )}
                            {restaurant.facebook && (
                              <InfoContainer>
                                <div className="title-info">
                                  <i className="icon-grande-table-28" />
                                  <a
                                    href={`https://www.facebook.com/${restaurant.facebook}`}
                                    target="_blank"
                                  >
                                    {restaurant.facebook}
                                  </a>
                                </div>
                              </InfoContainer>
                            )}
                            {restaurant.instagram && (
                              <InfoContainer>
                                <div className="title-info">
                                  <i className="icon-grande-table-29" />
                                  <a
                                    href={`https://www.instagram.com/${restaurant.instagram}`}
                                    target="_blank"
                                  >
                                    {restaurant.instagram}
                                  </a>
                                </div>
                              </InfoContainer>
                            )}
                            <InfoContainer>
                              <div className="title-info">
                                <i className="icon-grande-table-30" />
                                Horario de Atención:
                              </div>
                              <div className="item-info">
                                {!this.state.displaySchedule && (
                                  <div>
                                    Hoy:
                                    {branch.schedule.today}
                                    <ViewMore onClick={this.display}>
                                      Ver todos los horarios
                                      <i className="icon-grande-table-10" />
                                    </ViewMore>
                                  </div>
                                )}
                                {this.state.displaySchedule && (
                                  <div>
                                    <CustomTable onClick={this.display}>
                                      <tbody>{scheduleRange}</tbody>
                                    </CustomTable>
                                    <ViewMore onClick={this.display}>
                                      Ver horario de hoy
                                      <i className="icon-grande-table-32" />
                                    </ViewMore>
                                  </div>
                                )}
                              </div>
                            </InfoContainer>
                          </div>
                        </CustomPanel>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-lg-12">
                      <StyledRowSecondary>
                        <Map
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.API_GOOGLE}&v=3.exp&libraries=geometry,drawing,places`}
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<StyledContainerMap />}
                          mapElement={<div style={{ height: `100%` }} />}
                          center={centerMap}
                          currentPosition={currentPosition}
                          zoom={zoom}
                          handleClick={this.handleClick}
                          markers={restaurant.subsidiaries}
                        />
                        <MapLinkContainer onClick={this.handleClickToMap}>
                          <i className="icon-grande-table-26" style={{ fontSize: '21px' }} />
                          Ver en el mapa
                        </MapLinkContainer>
                      </StyledRowSecondary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledRow>
        <Modal
          className="regular-reservation"
          isOpen={isModalOpened}
          onRequestClose={() => this.closeModal('normal')}
          style={reservationModalStyles}
        >
          <ModalContainer>
            <div className="row close-btn pt-1">
              <i className="icon-grande-table-21 close" onClick={() => this.closeModal('normal')} />
            </div>
            <Reservation
              restaurant={restaurant}
              fields={fields}
              customerId={customerId}
              identification={identification}
              closeModal={this.closeModal}
            />
          </ModalContainer>
        </Modal>
        <Modal
          isOpen={isIdentificationModalOpened}
          onRequestClose={() => this.closeIdentificationModal('normal')}
          style={modalIdentificationStyles}
        >
          <ModalContainerProcess className="container">
            <div className="row close-btn">
              <i
                className="icon-grande-table-21 close"
                onClick={() => this.closeIdentificationModal('normal')}
              />
            </div>
            <Identification
              restaurant={restaurant}
              closeIdentificationModal={this.closeIdentificationModal}
              retrieveFields={this.retrieveFields}
              retrieveCustomerId={this.retrieveCustomerId}
              retrieveIdentification={this.retrieveIdentification}
            />
          </ModalContainerProcess>
        </Modal>
        <Modal
          isOpen={isEveveModalOpened}
          onRequestClose={this.closeEveveModal}
          style={customStyles}
        >
          <ModalContainerProcess className="eveve">
            <div className="close-btn">
              <i className="icon-grande-table-21 close" onClick={this.closeEveveModal} />
            </div>
            <Eveve restaurant={restaurant} />
          </ModalContainerProcess>
        </Modal>
        <Modal
          isOpen={isConfirmationModalOpened}
          onRequestClose={this.closeConfirmationModal}
          style={confirmationModalStyles}
        >
          <ModalContainer className="confirmation">
            <div className="row close-btn">
              <i className="icon-grande-table-21 close" onClick={this.closeConfirmationModal} />
            </div>
            <ConfirmReservation restaurantName={restaurant.name} />
          </ModalContainer>
        </Modal>
        <Modal
          isOpen={isMembershipModalOpened}
          onRequestClose={() => this.closeMembershipModal('normal')}
          style={membershipModalStyles}
        >
          <ModalContainerProcess className="container membership">
            <div className="row close-btn">
              <i
                className="icon-grande-table-21 close"
                onClick={() => this.closeMembershipModal('normal')}
              />
            </div>
            <Membership closeMembershipModal={this.closeMembershipModal} hasOptions={hasOptions} />
          </ModalContainerProcess>
        </Modal>
      </StyledContainer>
    )
  }
}

export default RestaurantProfile
