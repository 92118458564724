import styled from 'styled-components'
import media from '../../../../styles/media'
import theme from '../../../../styles/theme'

export const ModalContainer = styled.div`
  .lbl {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: ${theme.purpleBrown};
  }

  .lbl-text {
    text-align: center;
  }

  .header {
    font-size: '20px';
    padding-bottom: '10px';
  }

  .content {
    font-size: '13px';
    letter-spacing: '1px';
    padding-bottom: '15px';
  }

  .roboto-bold {
    font-family: 'Roboto Bold';
  }

  .roboto-light {
    font-family: 'Roboto Light';
  }

  .close-btn {
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-right: 1rem;
    text-align: right;
    .close {
      cursor: pointer;
      z-index: 10;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${theme.purpleBrown};
    }
  }

  ${media.sm`
    .padding-46 {
      padding-left: 46px;
      padding-right: 46px;
    }
  `}
`
