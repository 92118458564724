import React, { Component } from 'react'
import '../../../../assets/fontello/css/gt-font.css'
import Button from '../../../Button'
import {
  ButtonContainer,
  LogoAndNameContainer,
  LogoContainer,
  SpecialityContainer,
  StyledBody,
  StyledCard,
  StyledImage,
  StyledImageContainer,
  StyledLogo,
  StyledWrapper,
  TitleContainer,
} from './style'

interface Props {
  image: string
  icon: string
  title?: string
  slug?: string
  specialties?: Specialties[]
  hasEveve?: boolean
}

interface Specialties {
  name: string
  categories: any[]
}

class RestaurantItem extends Component<Props> {
  render() {
    const { icon, image, title, slug, specialties, hasEveve } = this.props

    return (
      <StyledCard className="GTABLE_REST_SELEC col-lg-4 col-sm-6 col-xs-12">
        <StyledWrapper>
          <StyledImageContainer>
            <div className={`eveve-gift ${hasEveve ? '' : 'hide'}`}>
              <i className="icon-regalo"></i>
            </div>
            <StyledImage src={image} alt={`${slug}-Grande Table`} />
          </StyledImageContainer>
          <LogoAndNameContainer className="container-fluid">
            <div className="row title-logo cat-grp">
              <TitleContainer>{title.toLowerCase()}</TitleContainer>
              <LogoContainer>
                <StyledLogo src={icon} alt={`${title}-Grande Table`} />
              </LogoContainer>
              <SpecialityContainer>
                {specialties &&
                  specialties.map((specialty: any, key: number) => {
                    if (specialty) {
                      return (
                        <div className="row category-container" key={key}>
                          <i className={specialty.icon} style={{ fontSize: '21px' }} />
                          <div>{specialty.categories}</div>
                        </div>
                      )
                    }
                  })}
              </SpecialityContainer>
            </div>
          </LogoAndNameContainer>
          <StyledBody>
            <ButtonContainer>
              <Button data-store={title} link={`events/burger-fest/${slug}`} type="secondary" text="Reservar" />
            </ButtonContainer>
          </StyledBody>
        </StyledWrapper>
      </StyledCard>
    )
  }
}

export default RestaurantItem
