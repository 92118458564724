import { IPageEvent, PageEventProps } from './IPageEvent'

export class EventAction implements IPageEvent {
  private readonly TypeEvent: string
  private readonly ActionField: string
  private readonly GroupActionEvent: string

  constructor(typeEvent: string, actionField: string, groupActionEvent: string) {
    this.TypeEvent = typeEvent
    this.ActionField = actionField
    this.GroupActionEvent = groupActionEvent
  }

  initialize({
    eventData,
    quantity,
    customerData,
    purchaseData,
  }: {
    eventData: any[] | any
    quantity?: number
    customerData?: any
    purchaseData?: any
  }): PageEventProps {
    return {
      event: this.TypeEvent,
      [this.TypeEvent]: {
        [this.GroupActionEvent]: {
          actionField: { list: this.ActionField },
          ...this.getCustomerEvent(customerData),
          ...this.getPurchaseEvent(quantity, purchaseData),
          ...this.getEvents(eventData),
        },
      },
    }
  }

  getCustomerEvent(customerData: any): any {
    if (customerData) {
      return {
        customer: {
          identification: customerData.identification,
          name: customerData.firstName + ' ' + customerData.lastName,
          mobile: customerData.phone,
          email: customerData.email,
        },
      }
    }
  }

  getPurchaseEvent(quantity: any, purchaseData?: any): any {
    if (purchaseData) {
      return {
        purchase: {
          number: purchaseData?.transaction?.id,
          quantity: quantity,
          tax: purchaseData.tax,
          subtotal: purchaseData.subtotal,
          total: purchaseData.total,
          purchasedWith: purchaseData.purchased_wit_gt ? 'Grande Table' : 'Diners Club',
        },
      }
    }
  }

  getEvents(eventData: any): any {
    if (eventData && eventData.items) {
      return {
        item: eventData.items.map((eventItem: any) => {
          const {item: {id, title, total_tickets}} = eventItem
          return {
            event: {
              id: id,
              name: title,
              total_tickets: total_tickets,
            },
          }
        }),
      }
    }

    return {
      item: {
        event: {
          id: eventData.id,
          name: eventData.title,
          total_tickets: eventData.total_tickets,
        },
      },
    }
  }
}
