import styled from 'styled-components'
import media from '../../styles/media'

export const BurgerContainer = styled.div`
  #events-sec {
    .container-btn-modal {
      margin: 1rem auto 2rem;
      padding: 1rem;
      left: 0;
      height: 50px;
      cursor: pointer;
      .filter-btn-txt {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.81px;
        text-align: center;
        flex: 1 0 auto;
        height: 100%;
      }
      i {
        left: 80%;
      }
    }
  }
`

export const Title = styled.div`
  display: none;
  ${media.md`
  display: flex;
  width: 100%;
  height: 260px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative; 
  `}
`

export const StyledImg = styled.img`
  width: 100%;
  height: 350px;
  @media(max-width: 768px) {
    display: none;
  }
  ${media.md`
    width: 85%;
    height: 310px;
  `}
  ${media.lg`
    width: 75%;
    height: 480px;
  `}
`
export const StyledImgMobile = styled.img`
  width: 100%;
  @media(min-width: 768px) {
    display: none;
  }
`

