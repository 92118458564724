import { Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import React from 'react'
import BurgerPage from '../components/BurgerFestProfile'
import BurgerSection from '../components/BurgerFestSection'
import Event from '../components/Event'
import EventsSection from '../components/EventsSection'
import Layout from '../components/Layout'

export default () => {
  return (
    <Layout themeType="dark" currentPage={'events'}>
      <Router>
        <EventsSection path={withPrefix('/events')} />
        {process.env.WITH_BURGER_FEST === 'active' && <BurgerSection path={withPrefix('/events/burger-fest')} />}
        <Event path={withPrefix('/events/:slug')} />
        {process.env.WITH_BURGER_FEST === 'active' && <BurgerPage path={withPrefix('/events/burger-fest/:slug')} />}
      </Router>
    </Layout>
  )
}
