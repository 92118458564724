import React, { Component } from 'react'

import { ParticipantDetail } from './style'

const facebook = require('../../../../assets/images/general/facebook-logo.svg')
const instagram = require('../../../../assets/images/general/icon-instagram-n.svg')
const youtube = require('../../../../assets/images/general/youtube.svg')
const website = require('../../../../assets/images/general/icon-link.svg')

interface Props {
  name: string
  logo: string
  category?: string
  restaurant?: string
  description: string
  social: any
  event: string
}

class ParticipanDetail extends Component<Props> {
  render() {
    const { name, category, logo, restaurant, description, social, event } = this.props
    return (
      <ParticipantDetail>
        {category ? (
          <img src={logo} className="participant-logo" alt={name + '-' + event + '-Grande Table'} />
        ) : (
          <img src={logo} className="participant-logo-circle" alt={name + '-' + event + '-Grande Table'} />
        )}
        <div className="participant-title">{name}</div>
        {category ? (
          <div className="participant-category">{category}</div>
        ) : (
          <div className="participant-restaurant">{restaurant}</div>
        )}
        <div className="participant-detail">{description}</div>
        {social['instagram'] && (
          <a href={social['instagram']}>
            <img src={instagram} className="social" />
          </a>
        )}
        {social['facebook'] && (
          <a href={social['facebook']}>
            <img src={facebook} className="social" />
          </a>
        )}
        {social['youtube'] && (
          <a href={social['youtube']}>
            <img src={youtube} className="social" />
          </a>
        )}
        {social['website'] && (
          <a href={social['website']}>
            <img src={website} className="social" />
          </a>
        )}
      </ParticipantDetail>
    )
  }
}

export default ParticipanDetail
