import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'
const background = require('../../assets/images/pages/bg-events.jpg')

export const StyledRow = styled.div`
  align-items: center;
  margin-top: 0;
`

export const EventTopBackground = styled.div`
  width: 100%;
  background-image: url(${background});
  background-size: 0% 0%;
  background-repeat: no-repeat;
  ${media.sm`  
    background-size: 50% 100%;
  `};
  .flex {
    display: flex;
    justify-content: center;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    ${media.sm`  
      justify-content:left;
      background-size: 0% 0%;
    `};
  }

  @media screen and (min-width: 451px) and (max-width: 767px) {
    .container {
      max-width: 100% !important;
    }
  }
  @media screen and (min-width: 325px) and (max-width: 450px) {
    .container {
      max-width: 100% !important;
    }
  }
  @media screen and (max-width: 324px) {
    .container {
      max-width: 100% !important;
      .flex {
        height: 254px;
      }
    }
  }
`
export const EventBottomBackground = styled.div`
  width: 100%;
`

export const EventImage = styled.div`
  align-self: center;
  max-width: 100%;
  ${media.sm`  
    padding: 20px 1rem;
  `};
  .box {
    border: 2px solid #c59d5f;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      margin-top: 14px;
      margin-left: 14px;
      margin-bottom: -14px;
    }
  }
  .logobox {
    display: flex;
    position: relative;
    ${media.sm`  
      width: 100%;
      margin-top: -40px;
      img {
        margin: auto;
      }
    `};
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: row;
    .box {
      margin: 1rem 0 1rem auto;
    }
    .logobox {
      display: initial;
      margin: auto 0;
    }
  }
`
export const EventDetails = styled.div`
  font-stretch: normal;
  margin-top: 2rem;
  ${media.sm`
    padding-left: 3rem;
  `}

  .title {
    font-family: DroidSerif;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: rgb(197, 157, 95);
  }
  .event-type {
    margin: 1rem 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.23px;
    text-align: justify;
  }
  .event-description {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: justify;
  }
  .price {
    font-family: DroidSerif;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: black;
  }
  .price-title {
    font-weight: normal;
    font-size:7px;
    color: black;
  }

  .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;
  }
  
  .flex-items:nth-child(1) {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
  }

  .block {
    margin: 0;
    padding: 1rem 0;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: justify;

    &.date-place {
      font-size: 12px;
    }

    &.data-block {
      border-bottom: solid #dfdfdf 1px;
    }

    .no-margins {
      margin: 0;
      padding: 0;
    }

    .capitalize-month {
      text-transform: capitalize;
    }

    .item-space {
      padding-right: 0.2rem;
    }

    .bottom-padding {
      padding-bottom: 0.3rem;
      &.bottom-padding-more {
        padding-bottom: 0.5rem;
      }
      &.no-left-padding {
        padding-left: 0;
      }
    }

    .top-padding {
      padding-top: 0.5rem;
    }

    .bold-text {
      font-weight: bold;
      &.colored {
        color: #c59d5f;
      }
    }

    .align-items-center {
      align-items: center;
    }

    .simple-text-price {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 1.54px;
      text-align: justify;
      color: ${theme.purpleBrown};
      &.colored {
        color: #c59d5f;
      }
    }

    .quantity {
      line-height: 1.54;
      padding-bottom: 1rem;
    }
  }

  .buy-button {
    margin: auto auto auto 0;
    padding: 1rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  @media screen and (max-width: 767px) {
    .block {
      .row-on-small {
        flex-direction: row;
        display: flex;
        justify-content: center;
        .bold-text {
          padding-right: 0.5rem;
        }
      }
      .quantity {
        justify-content: center;
        text-align: center;
      }
    }
    .buy-button {
      width: 100%;
    }
  }
`

export const SponsorsContainer = styled.div`
  &.hide-container {
    display: none;
  }

  ${media.sm`
    padding-left: 3rem;
  `}

  padding-top: 2rem;
  .sponsors-title {
    width: 100%;
    margin-bottom: 1rem;
    padding-bottom: 0.6rem;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.08px;
    text-align: center;
    color: ${theme.purpleBrown};
    border-bottom: solid #dfdfdf 1px;
  }
  .sponsors-bar {
    width: 100%;
    .col {
      text-align: center;
    }
  }
  .terms-bar {
    padding: 10px;
    width: 100%;
    text-align: justify;
  }
  .map-title {
    padding-bottom: 0.4rem;
    font-family: DroidSerif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c59d5f;
  }
`
export const StyledContainerMap = styled.div`
  height: 285px;
  ${media.md`
    height: 285px;
  `};
`
export const StyledContainer = styled.div`
  color: black;
  font-weight: 300;
  letter-spacing: 1.9px;
  .display-mobile {
    display: flex;
  }
  .display-desktop {
    display: none;
  }
  ${media.sm`  
  .display-mobile{
    display:none
  }
  .display-desktop {
    display: block;
  }
  `};
`
export const MapLinkContainer = styled.div`
  margin-bottom: 20px;
  padding: 0.4rem 0;
  color: ${theme.purpleBrown};
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
`
export const Separator = styled.div`
  width: 56px;
  height: 0px;
  margin-top: 24px;
  border: 1px solid #A9A0A0;
  transform: rotate(90deg);
`
