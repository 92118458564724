import React, { Component } from 'react'
import { navigate } from 'gatsby'

import {
  StyledCard,
  StyledBody,
  StyledImage,
  StyledWrapper,
  LogoAndNameContainer,
  SpecialityContainer,
  EventItemContainer,
} from '../../RestaurantsSection/components/RestaurantItem/style'
import '../../../assets/fontello/css/gt-font.css'
import { LinkContainer, TitleContainer, StyledImageContainer, ButtonContainer } from './style'
import { GtmEventsLoad } from '../../../helpers/gtm'
import { EventAction } from '../../../helpers/GTM/EventAction'
interface Props {
  event?: any
}

class EventItem extends Component<Props> {
  monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

  render() {
    const { event } = this.props
    const openEvent = () => {
      GtmEventsLoad(new EventAction('eventClick', 'click', 'event').initialize({ eventData: event }))
      navigate('/events/' + event.slug)
    }

    return (
      <EventItemContainer className="col-lg-4 col-sm-6 col-xs-12">
        <div className={`unavailable-text ${event.total_tickets == 0 ? ' col-12' : 'hide-text'}`}>Agotado</div>
        <StyledCard className={event.total_tickets == 0 ? 'active-opacity' : ''} data-store={event.title}>
          <StyledWrapper style={{ cursor: 'default' }}>
            <StyledImageContainer>
              <StyledImage src={event.bg_image} alt={event.title + '-Grande Table'} />
            </StyledImageContainer>
            <div className="row" style={{ margin: '0 auto', alignItems: 'start' }}>
              <LogoAndNameContainer className="col-6 container-fluid" style={{ paddingLeft: '0.5rem', paddingTop: 0, paddingRight: 0 }}>
                <div className="title-logo">
                  <TitleContainer>{event.title}</TitleContainer>
                </div>
                {event.type && (
                  <SpecialityContainer style={{ width: '100%', paddingTop: '0.5rem' }}>
                    <div style={{ width: '100%' }}>{event.type}</div>
                  </SpecialityContainer>
                )}
              </LogoAndNameContainer>
              <StyledBody className="col-6" style={{ padding: 0, fontSize: '11px' }}>
                {event.total_tickets == 0 ? (
                  <ButtonContainer>
                    <LinkContainer className="no-tickets-link" type="secondary">
                      Comprar
                    </LinkContainer>
                  </ButtonContainer>
                ) : (
                  <ButtonContainer className="GTABLE_EVENT_SELEC item-link" data-event={event.title} onClick={openEvent}>
                    <LinkContainer type="secondary">Comprar</LinkContainer>
                  </ButtonContainer>
                )}
              </StyledBody>
            </div>
          </StyledWrapper>
        </StyledCard>
      </EventItemContainer>
    )
  }
}

export default EventItem
