import styled from 'styled-components'
import Theme from '../../../styles/theme'
import media from '../../../styles/media'
import theme from '../../../styles/theme'

interface LinkProps {
  type?: string
}

export const StyledImageContainer = styled.div`
  position: relative;
  .evt-date {
    position: absolute;
    top: 2px;
    left: 3px;
    font-family: Roboto;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    .month {
      padding: 0 0.5rem;
      font-weight: normal;
      background-color: #ffffff;
      color: ${theme.purpleBrown};
    }
    .day {
      font-weight: bold;
      background-color: ${theme.purpleBrown};
      color: #ffffff;
    }
  }
`

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0;
  justifyContent: 'end';
`

export const LinkContainer = styled.div`
  background: ${(props: LinkProps) =>
    props.type === 'primary' ? Theme.secondaryColor : 'transparent'};
  border: solid
    ${(props: LinkProps) =>
      props.type === 'primary' ? `2px ${Theme.secondaryColor}` : '1px white'};
  padding: 0.5rem 2.5rem;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  font-weight: 300;
  border-radius: 17.5px;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  text-align: center;
  margin: auto;

  &:hover {
    background: white;
    color: ${(props: LinkProps) => (props.type === 'primary' ? Theme.secondaryColor : '#242424')};
  }

  @media screen and (max-width: 767px) {
    display: inline-block;
    width: auto;
    position: relative;
    padding: 0.5rem 1.5rem;
    border-radius: 17.5px;
    margin: auto;
  }

  ${media.sm`
    padding: 0.5rem 1rem;
  `}

  ${media.lg`
    padding: 0.5rem 2rem;
  `}

  &.sold-out {
    border: solid 2px #dddddd;
    background: #dddddd;
    color: #969696;
    margin: 0;
    @media screen and (max-width: 767px) {
      margin: auto;
    }
  }
`
export const TitleContainer = styled.div`
  padding-top: 0.5rem;
  color: #c59d5f;
  font-family: DroidSerif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
`
