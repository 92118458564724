import styled from 'styled-components'

export const EventsContainer = styled.div`
  #events-sec {
    .container-btn-modal {
      margin: 1rem auto 2rem;
      padding: 1rem;
      left: 0;
      height: 50px;
      cursor: pointer;
      .filter-btn-txt {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.81px;
        text-align: center;
        flex: 1 0 auto;
        height: 100%;
      }
      i {
        left: 80%;
      }
    }
  }
`
