import styled from 'styled-components'
import theme from '../../../../styles/theme'

export const ParticipantDetail = styled.div`
  margin-bottom: 50px;
  display: inline-block;
  .participant-logo {
    display: block;
    width: 160px;
    height: 160px;
    margin-right: 15px;
    float: left;
    border: 1px solid #dfdfdf;
  }

  .social {
    width: 24px;
    height: 24px;
    float: right;
    margin-right: 10px;
  }

  .participant-logo-circle {
    display: block;
    border-radius: 50%;
    width: 160px;
    height: 160px;
    margin-right: 15px;
    float: left;
  }

  .participant-title {
    font-family: DroidSerif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${theme.camel};
  }

  .participant-category .participant-restaurant {
    font-family: Blanc;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    margin-bottom: 10px;
    color: #989898;
  }

  .participant-detail {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #808080;
  }

  @media screen and (max-width: 700px) {
    .participant-logo {
      margin: 10px auto;
      text-align: center;
      float: none;
    }

    .participant-logo-circle {
      display: block;
      margin: 10px auto;
      text-align: center;
      float: none;
    }

    .participant-title {
      text-align: center;
    }

    .participant-category {
      text-align: center;
    }

    .participant-restaurant{
      text-align: center;
    }

    .participant-detail {
      text-align: justify;
    }
  }
`
