import React, { Component } from 'react'
import {
  FilterTitle,
  CustomLabel,
  CheckboxContainer,
  FilterContainer
} from '../../RestaurantsSection/components/RestaurantFilters/style'
import '../../../assets/fontello/css/gt-font.css'

class EventFilters extends Component<
  {
    applyFilters: (eventType: any, isChecked: any) => void
    eventTypes: any[]
    filters: any[]
    isModal: boolean
  },
  any
> {
  constructor(props: any) {
    super(props)
  }

  generateFilters = () => {
    const title = 'Tipo de Evento'
    const { eventTypes, filters } = this.props

    return (
      <div>
        <FilterTitle>
          <div className="row">
            <i className="icon-grande-table-24" />
            <div className={`padded-title ${title.length > 20 ? 'large' : ''}`}>{title}</div>
          </div>
        </FilterTitle>
        <CheckboxContainer>
          {eventTypes.map((eventType: any, index: number) => {
            for (const filter of filters) {
              if (filter === eventType.event_type) {
                return (
                  <div key={index}>
                    <div>{this.showCheckBox(eventType, true)}</div>
                  </div>
                )
                break
              }
            }
            return (
              <div className="checkbox" key={index}>
                {this.showCheckBox(eventType, false)}
              </div>
            )
          })}
        </CheckboxContainer>
      </div>
    )
  }

  showCheckBox = (eventType: any, isChecked: boolean) => {
    return (
      <CustomLabel>
        <input
          className="GTABLE_EVEN_TIPO"
          data-type={eventType.event_type}
          type="checkbox"
          value={eventType.id}
          onChange={e => this.handleOptions(e, eventType)}
          checked={isChecked}
        />
        {eventType.event_type}
        <span className="checkmark" />
      </CustomLabel>
    )
  }

  handleOptions = (e: any, eventType: any) => {
    this.props.applyFilters(eventType, e.target.checked)
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <FilterContainer className={`col-md-12 ${this.props.isModal ? 'modal' : 'desktop'}`}>
            {this.generateFilters()}
          </FilterContainer>
        </div>
      </div>
    )
  }
}
export default EventFilters
